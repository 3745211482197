@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Rubik";
  src: url("./fonts/Rubik-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

body {
  margin: 0;
  padding: 0;
  font-family: Rubik, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #252631;
}
a {
  text-decoration: none;
  color: black;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: rgba(239, 239, 239, 0.38);
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
